import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Line from '../../img/Apply/line.svg';
import { useApply } from '../../context/ApplyContext';

const ask_list = [
  'AI 솔루션 도입',
  'AI 바우처 지원사업 문의',
  '제휴 문의',
  '기타',
];

const AskList = () => {
  const { apply, setApply } = useApply();

  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  // checkedList의 state가 바뀔떄마다 setApply를 호출해 값을 업데이트
  // checkedList를 context에 넣어버리는 방식으로 추후 리팩토링예정
  useEffect(() => {
    setApply({ ...apply, checkedList });
  }, [checkedList]);

  // 문의사항
  const checkedItemHandler = (value: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedList((prev) => [...prev, value]);
      return;
    }

    if (!isChecked && checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));
      return;
    }

    return;
  };

  const checkHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setIsChecked(!isChecked);
    checkedItemHandler(value, e.target.checked);
  };

  return (
    <div>
      <LabelContainer>
        <Label>문의 사항 </Label>
        <SubLabel>(복수 응답 가능)</SubLabel>
      </LabelContainer>
      <ListContainer>
        {ask_list.map((item, id) => (
          <div key={id}>
            <RowContainer>
              <RowLabel htmlFor={item}>{item}</RowLabel>
              <CheckBox
                type='checkbox'
                id={item}
                checked={checkedList.includes(item)}
                onChange={(e) => checkHandler(e, item)}
              />
            </RowContainer>
            {id < ask_list.length - 1 ? <LineImg src={Line} /> : <></>}
          </div>
        ))}
      </ListContainer>
      <CommentDiv>
        <Comment>아래 링크를 눌러 내용을 주의 깊게 읽어주세요.</Comment>
        <Comment>
          체크 박스를 선택하면, 다음 항목을 모두 읽고 동의한 것으로 간주합니다.
        </Comment>
      </CommentDiv>
    </div>
  );
};

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
`;

const Label = styled.span`
  color: white;
  font-size: 1rem;
  font-family: Pretendard-SemiBold;
  white-space: pre-wrap;
`;

const SubLabel = styled.span`
  color: white;
  font-size: 0.8rem;
  font-family: Pretendard-Light;
`;

const ListContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LineImg = styled.img`
  width: 100%;
  height: 3px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direciton: row;
  justify-content: space-between;
  padding: 13px 15px;
`;

const CheckBox = styled.input``;

const RowLabel = styled.label`
  font-size: 0.9rem;
  font-family: Pretendard-Regular;
`;

const CommentDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 20px;
`;

const Comment = styled.p`
  font-size: 0.8rem;
  font-family: Pretendard-Light;
  color: white;
  margin-bottom: 5px;
`;

export default AskList;
