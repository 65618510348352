import { Link } from 'react-scroll';
import styled from 'styled-components';
import Top from '../../img/top.svg';

const ToMainBtn = () => {
  return (
    <Link to='top' spy={true} smooth={true} duration={1000}>
      <ToMain src={Top} />
    </Link>
  );
};

const ToMain = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 2% 1.5% 0;
  @media (max-width: 450px) {
    margin: 0 7% 3% 0;
  }
`;

export default ToMainBtn;
