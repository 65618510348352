import React from 'react';
import styled from 'styled-components';

type FormContainerProps = {
  labelName: string;
  subLabel?: string;
  id: string;
  placeholder: string;
  type: React.HTMLInputTypeAttribute;
  half?: boolean;
  essential?: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const FormContainer = ({
  labelName,
  subLabel,
  id,
  placeholder,
  type,
  half = false,
  essential = false,
  value,
  onChange,
}: FormContainerProps) => {
  return (
    <Div>
      <LabelContainer>
        <Label>{labelName}</Label>
        {subLabel ? <SubLabel subLabel={subLabel}>{subLabel}</SubLabel> : <></>}
        {essential ? <Essential essential={essential}>*</Essential> : <></>}
      </LabelContainer>
      <InputForm
        id={id}
        type={type}
        placeholder={placeholder}
        half={half}
        onChange={onChange}
        value={value}
      />
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
`;

const Label = styled.span`
  color: white;
  font-size: 0.9rem;
  font-family: Pretendard-SemiBold;
  white-space: pre-wrap;
`;

const SubLabel = styled.span<{ subLabel: string }>`
  color: white;
  font-size: 0.8rem;
  font-family: Pretendard-Light;
`;

const Essential = styled.span<{ essential: boolean }>`
  color: #ff0000;
  font-size: 1rem;
  font-family: -Medium;
`;

const InputForm = styled.input<{ half: boolean }>`
  &::placeholder {
    font-size: ${(props) => (props.half ? 0.7 : 0.9)}rem;
    font-family: Pretendard-Regular;
    color: #c4c4c4;
  }
  &:focus {
    box-shadow: 0 0 0 2px #a0ceef inset;
  }

  width: ${(props) => (props.half ? 94 : 95)}%;
  height: 45px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  padding-left: ${(props) => (props.half ? 3 : 4)}%;
  outline: none;
`;

export default FormContainer;
