import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
    @font-face {
        font-family: "Pretendard-Thin";
        src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff")
        format("woff");
        font-weight: 200;
        font-style: normal;
    }

    @font-face {
        font-family: "Pretendard-Light";
        src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff")
        format("woff");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Pretendard-Regular";
        src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
        format("woff");
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: "Pretendard-Medium";
        src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
        format("woff");
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: "Pretendard-SemiBold";
        src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
        format("woff");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Pretendard-Bold";
        src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
        format("woff");
        font-weight: 800;
        font-style: normal;
    }

    * {
        box-sizing: border-box
        margin: 0;
        padding: 0;
    }

    body {
        background-color: black;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
    
    img {
        display: block;
        width: 100%;
        height: 100%
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

export default GlobalStyle;
