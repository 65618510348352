import styled from 'styled-components';

type TitleProps = {
  title: string;
  subTitle: string;
};

const TitleForm = ({ title, subTitle }: TitleProps) => {
  return (
    <TextContainer>
      <Category>{title}</Category>
      <CateCommemt>{subTitle}</CateCommemt>
    </TextContainer>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 70px;
`;

const Category = styled.p`
  font-family: Pretendard-SemiBold;
  font-size: 1.3rem;
  color: #a0ceef;
  margin: 1% 8% 0 0;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.7rem;
  }
`;

const CateCommemt = styled.p`
  font-family: Pretendard-Bold;
  font-size: 2.2rem;
  color: white;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 1.7rem;
  }
  @media (max-width: 450px) {
    font-size: 1.1rem;
  }
`;

export default TitleForm;
