import styled from 'styled-components';
import { Element } from 'react-scroll';
import FormContainer from './FormContainer';
import Introduce from './Introuce';
import AskList from './AskList';
import { useRef, useState } from 'react';
import { uid } from 'uid';
import { database } from '../../firebase';
import { set, ref } from '@firebase/database';
import ApplyModal from './ApplyModal';
import { useApply } from '../../context/ApplyContext';
import Line from '../../img/Apply/line.svg';
import emailjs from '@emailjs/browser';
import axios from 'axios';

const agree_list = [
  '(필수) 소개서, 뉴스레터 등 Sigor Inc. 정보 수신에 동의합니다.',
  '(필수) 개인정보 수집 및 이용에 동의합니다.',
];

export const sendSlackMessage = async (url: string, data: any) => {
  console.log('sendSlackMessage');
  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        url: 'https://hooks.slack.com/services/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',
        data: {
          text: 'AI 바우처 문의 신청',
          attachments: [
            {
              color: '#2eb886',
              fields: [
                {
                  title: '신청자 정보',
                  value: `성함: ${data.name}\n이메일: ${data.email}\n담당자 전화번호: ${data.phone}\n대표자 전화번호: ${data.subPhone}`,
                  short: false,
                },
                {
                  title: '접수 내용',
                  value: `도입 예정 사이트: ${data.site}\n문의 사항: ${data.checkedList}`,
                  short: false,
                },
              ],
            },
          ],
        },
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('response: ', response);
  } catch (err) {
    console.warn(err);
  }
};

const ApplyForm = () => {
  const { apply, setApply } = useApply();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [agreeChecked, setAgreeChecked] = useState<boolean[]>([true, true]);

  const checkAgreeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
    id: number,
  ) => {
    const newAgree = [...agreeChecked];
    newAgree[id] = !newAgree[id];
    setAgreeChecked(newAgree);
  };

  const form = useRef();

  const writeData = () => {
    if (agreeChecked.includes(false)) {
      setIsError(true);
      return;
    }
    if (
      apply.name === '' ||
      apply.company === '' ||
      apply.email === '' ||
      apply.phone === ''
    ) {
      setIsError(true);
      return;
    }
    setIsError(false);
    const uuid = uid();
    set(ref(database, 'apply_list/' + uuid), {
      apply,
      uuid,
    });
    setApply({
      name: '',
      company: '',
      email: '',
      phone: '',
      subPhone: '',
      site: '',
      checkedList: [],
    });
    setAgreeChecked([true, true]);
    setIsError(false);
    const formData = {
      name: apply.name,
      company: apply.company,
      email: apply.email,
      phone: apply.phone,
      subPhone: apply.subPhone,
      site: apply.site,
      checkedList: apply.checkedList.join(', '),
    };
    const templateParams = {
      name: formData.name,
      company: formData.company,
      email: formData.email,
      phone: formData.phone,
      subPhone: formData.subPhone,
      site: formData.site,
      checkedList: formData.checkedList,
    };
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID as string,
        process.env.REACT_APP_TEMPLATE_KEY as string,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY as string,
      )
      .then(
        (result) => {
          setModalShow(true);
        },
        (error) => {},
      );
    setModalShow(true);
    sendSlackMessage(
      'https://ub362ctqc6.execute-api.ap-northeast-2.amazonaws.com/default/slack-bot',
      formData,
    );
    setInterval(() => window.location.replace('/'), 2000);
  };

  return (
    <Element name='ApplyForm'>
      <DivContainer>
        <Introduce />
        <FormWrapper>
          <FormContainer
            labelName='성함'
            placeholder='성함을 입력해 주세요'
            id='1'
            type='text'
            essential={true}
            value={apply.name}
            onChange={(e) => {
              setApply({ ...apply, name: e.currentTarget.value });
            }}
          />
          <FormContainer
            labelName='회사명'
            placeholder='회사명을 입력해 주세요'
            id='2'
            type='text'
            essential={true}
            value={apply.company}
            onChange={(e) =>
              setApply({ ...apply, company: e.currentTarget.value })
            }
          />
          <FormContainer
            labelName='이메일'
            placeholder='이메일을 입력해 주세요'
            id='3'
            type='email'
            essential={true}
            value={apply.email}
            onChange={(e) =>
              setApply({ ...apply, email: e.currentTarget.value })
            }
          />
          <SmallForm>
            <FormContainer
              labelName='전화번호 '
              subLabel='(담당자)'
              placeholder='담당자 번호를 입력해 주세요'
              id='4'
              type='tel'
              half={true}
              essential={true}
              value={apply.phone}
              onChange={(e) =>
                setApply({ ...apply, phone: e.currentTarget.value })
              }
            />
            <FormContainer
              labelName='전화번호 '
              subLabel='(대표)'
              placeholder='대표 번호를 입력해 주세요'
              id='5'
              type='tel'
              half={true}
              value={apply.subPhone ?? ''}
              onChange={(e) =>
                setApply({ ...apply, subPhone: e.currentTarget.value })
              }
            />
          </SmallForm>
          <FormContainer
            labelName='도입 예정 사이트'
            placeholder='URL을 입력해 주세요'
            id='6'
            type='url'
            value={apply.site ?? ''}
            onChange={(e) =>
              setApply({ ...apply, site: e.currentTarget.value })
            }
          />
          <AskList />
          <AgreeDiv>
            <UnderLineImg src={Line} />
            {agree_list.map((item, id) => (
              <AgreeRow key={id}>
                {id === 0 ? (
                  <>
                    <AgreeCheck
                      type='checkbox'
                      defaultChecked={agreeChecked[id]}
                      onChange={(e) => checkAgreeHandler(e, item, id)}
                    />
                    <AgreeContent>{item}</AgreeContent>
                  </>
                ) : (
                  <>
                    <AgreeCheck
                      type='checkbox'
                      defaultChecked={agreeChecked[id]}
                      onChange={(e) => checkAgreeHandler(e, item, id)}
                    />
                    <AgreeLink
                      href='https://fantastic-scarer-d1e.notion.site/c8de77986a244f86b870285ca7c7af03'
                      target='_self'
                    >
                      {item}
                    </AgreeLink>
                  </>
                )}
              </AgreeRow>
            ))}
          </AgreeDiv>
          <ErrorBox>
            {isError ? (
              <ErrorMsg>*필수 항목을 모두 입력 또는 동의해주세요.</ErrorMsg>
            ) : (
              <></>
            )}
          </ErrorBox>
          <ApplyBtn type='submit' onClick={writeData}>
            신청하기
          </ApplyBtn>
          {modalShow ? (
            <ApplyModal
              modalShow={modalShow}
              setModalShow={(v: boolean) => {
                setModalShow(v);
              }}
            />
          ) : (
            <></>
          )}
        </FormWrapper>
      </DivContainer>
    </Element>
  );
};

const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15%;
  @media (max-width: 1840px) {
    padding: 0 7%;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const SmallForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1240px) {
    flex-direction: column;
  }
`;

const FormWrapper = styled.div`
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  width: 35%;
  padding: 2% 2.3%;
  @media (max-width: 450px) {
    width: 90%;
    margin: 3%;
  }
`;

const ApplyBtn = styled.button`
  width: 100%;
  background-color: #083e8d;
  border-radius: 10px;
  border: none;
  color: white;
  font-family: Pretendard-SemiBold;
  font-size: 1rem;
  padding: 15px 0;
  cursor: pointer;
`;

const ErrorBox = styled.div`
  width: 250px;
  height: 20px;
  display: flex;
  justify-content: center;
`;

const ErrorMsg = styled.p`
  font-family: Pretendard-Medium;
  font-size: 0.9rem;
  color: red;
`;

const UnderLineImg = styled.img`
  width: 100%;
  height: 30px;
`;

const AgreeDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const AgreeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const AgreeContent = styled.label`
  font-family: Pretendard-Regular;
  font-size: 0.8rem;
  color: #a0ceef;
  @media (max-width: 450px) {
    font-size: 0.7rem;
  }
`;

const AgreeCheck = styled.input`
  color: #a0ceef;
  margin-right: 7px;
  word-break: keep-all;
`;

const AgreeLink = styled.a`
  text-decoration: underline;
  text-underline-position: under;
  color: #a0ceef;
  cursor: pointer;
  font-family: Pretendard-Regular;
  font-size: 0.8rem;
  color: #a0ceef;
  @media (max-width: 450px) {
    font-size: 0.7rem;
  }
`;
export default ApplyForm;
