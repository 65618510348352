import styled from 'styled-components';
import SigorLogo from '../../img/logo04.png';
import InquireButton from './InquireButton';

const Header = () => {
  return (
    <HeaderWrapper>
      <LogoImg src={SigorLogo} />
      <InquireButton />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 15%;
  backdrop-filter: blur(10px);
  @media (max-width: 450px) {
    height: 50px;
  }
`;

const LogoImg = styled.img`
  width: 56px;
  height: 24px;
  preserveaspectratio: none;
  margin-top: 15px;
  @media (max-width: 450px) {
    width: 46px;
    height: 20px;
  }
`;

export default Header;
