import styled from 'styled-components';
import Close from '../../img/close.svg';
import { useEffect } from 'react';

interface isShow {
  modalShow: boolean;
  setModalShow: (v: boolean) => void;
}

const ApplyModal = ({ modalShow, setModalShow }: isShow) => {
  const onClickClose = () => {
    setModalShow(false);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setModalShow(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Modal>
      <CloseBtn onClick={onClickClose}>
        <CloseIcon src={Close} alt='닫기' />
      </CloseBtn>
      <ModalContent>신청이 접수되었습니다.</ModalContent>
      <ModalContent>감사합니다.</ModalContent>
    </Modal>
  );
};

const Modal = styled.div`
  width: 250px;
  height: 80px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: fixed;
  bottom: 100px;
  left: calc(50% - 100px);
  opacity: 0.9;
`;

const ModalContent = styled.p`
  font-family: Pretendard-Regular;
  font-size: 17px;
  margin-bottom: 8px;
`;

const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  margin: 10px 0 0 200px;
`;

const CloseIcon = styled.img`
  height: 12px;
`;

export default ApplyModal;
