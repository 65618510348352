import styled from 'styled-components';
import TitleForm from '../TitleFrom/TitleForm';

import select05 from '../../img/Select/select05.svg';
import select06 from '../../img/Select/select06.svg';

const Select = () => {
  return (
    <SelectDiv>
      <TitleForm title='선택 이유' subTitle='왜 Sigor일까요?' />
      <Content>
        <Dot>• </Dot>체계적인 프로세스로 솔루션을 제공합니다
      </Content>
      <img src={select06} alt='select06' />
      <Content>
        <Dot>• </Dot>기술적 역량이 보장되어 있습니다
      </Content>
      <img src={select05} alt='select05' />
    </SelectDiv>
  );
};

const SelectDiv = styled.div`
  padding: 8% 15%;
  margin: 170px 0;
  background-color: #121212;
  @media (max-width: 1840px) {
    padding: 6% 7%;
  }
`;

const Content = styled.p`
  font-family: Pretendard-Medium;
  font-size: 1.3rem;
  color: white;
  margin: 90px 0 30px 0;
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const Dot = styled.span`
  justify-content: center;
  white-space: pre-wrap;
`;

export default Select;
