import GlobalStyle from './styles/globalStyles';
import MainLanding from './components/MainLanding/MainLanding';
import { ApplyProvider } from './context/ApplyContext';

function App() {
  return (
    <ApplyProvider>
      <GlobalStyle />
      <MainLanding />
    </ApplyProvider>
  );
}
export default App;
