import styled from 'styled-components';
import TitleForm from '../TitleFrom/TitleForm';
import techuse01 from '../../img/techuse/techuse01.svg';
import techuse02 from '../../img/techuse/techuse02.svg';
import techuse03 from '../../img/techuse/techuse03.svg';

const TechUse = () => {
  return (
    <TechUseDiv>
      <TitleForm
        title='기술 활용'
        subTitle='Sigor AI 솔루션, 이렇게 활용됩니다'
      />
      <TechUseContainer>
        <TechUseImg src={techuse01} />
        <TechUseImg src={techuse02} />
        <TechUseImg src={techuse03} />
      </TechUseContainer>
    </TechUseDiv>
  );
};

const TechUseDiv = styled.div`
  padding: 0 15%;
  margin: 170px 0;
  @media (max-width: 1840px) {
    padding: 0 7%;
  }
`;

const TechUseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
`;

const TechUseImg = styled.img`
  width: 100%;
  height: 35%;
  margin-bottom: 30px;
`;

export default TechUse;
