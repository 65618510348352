import styled from 'styled-components';
import SigorLogo from '../../img/logo04.png';
import Blog from '../../img/Footer/blog.svg';

const Footer = () => {
  return (
    <FooterDiv>
      <Content>
        <FooterLogo src={SigorLogo} />
        <InfoRow>
          <InfoContent
            href='https://fantastic-scarer-d1e.notion.site/c8de77986a244f86b870285ca7c7af03'
            target='_self'
          >
            개인정보 처리방침
          </InfoContent>
          <InfoContent
            href='https://fantastic-scarer-d1e.notion.site/f1ccc558294842b09c205264764101aa?pvs=4'
            target='_self'
          >
            서비스 이용약관
          </InfoContent>
        </InfoRow>
        <Company>Sigor Inc. | 서울시 강남구 도곡로 112, 서한빌딩 2층</Company>
        <Company>대표이사 : 홍주영 | 사업자 등록번호:814-88-02273</Company>
        <CopyWrap>
          <Copyright>COPYRIGHT 2023 Sigor Inc. ALL RIGHTS RESERVED</Copyright>
          <Copyright>Design Sigor Inc. by Icons by Icons8</Copyright>
        </CopyWrap>
      </Content>
      <IconWrap>
        <a href='https://blog.naver.com/sigor_inc' target='_self'>
          <BlogIcon src={Blog} />
        </a>
      </IconWrap>
    </FooterDiv>
  );
};

const FooterDiv = styled.div`
  padding: 4% 15% 0 15%;
  margin-top: 170px;
  background-color: #121212;
  height: 325px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1840px) {
    padding: 3% 6% 0 6%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterLogo = styled.img`
  width: 60px;
  height: 26px;
  preserveaspectratio: none;
  margin-top: 15px;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 230px;
  margin: 40px 0;
`;

const InfoContent = styled.a`
  font-family: Pretendard-Semibold;
  font-size: 0.8rem;
  color: white;
  text-decoration: underline;
  text-underline-position: under;
`;

const Company = styled.p`
  font-family: Pretendard-Regular;
  font-size: 1rem;
  white-space: pre-wrap;
  color: #c4c4c4;
  margin-bottom: 10px;
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const CopyWrap = styled.div`
  margin-top: 25px;
`;

const Copyright = styled.p`
  color: #5d5d5d;
  font-family: Pretendard-Light;
  font-size: 0.9rem;
  margin-bottom: 5px;
`;

const IconWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BlogIcon = styled.img`
  width: 46px;
  height: 46px;
  margin-bottom: 60px;
`;
export default Footer;
