import styled from 'styled-components';
import TitleForm from '../TitleFrom/TitleForm';

import tech0101 from '../../img/techIntro/tech0101.svg';
import tech0202 from '../../img/techIntro/tech0202.svg';
import tech0303 from '../../img/techIntro/tech0303.svg';
import tech0404 from '../../img/techIntro/tech0404.svg';

const techImg = [tech0101, tech0202, tech0303, tech0404];

const TechIntro = () => {
  return (
    <TechIntroDiv>
      <TitleForm
        title='기술 소개'
        subTitle='Sigor의 Solution으로 업무에 날개를 달아보세요'
      />
      <TechImgContiner>
        {techImg.map((item, id) => (
          <TechTwo src={item} />
        ))}
      </TechImgContiner>
    </TechIntroDiv>
  );
};

const TechIntroDiv = styled.div`
  padding: 0 15%;
  margin: 170px 0;
  @media (max-width: 1840px) {
    padding: 0 7%;
  }
`;

const TechImgContiner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TechTwo = styled.img`
  width: 23%;
  height: 40%;
`;

export default TechIntro;
