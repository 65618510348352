import React, {
  createContext,
  ReactNode,
  useContext,
  SetStateAction,
  useState,
} from 'react';

interface Apply {
  name: string;
  company: string;
  email: string;
  phone: string;
  subPhone?: string;
  site?: string;
  checkedList: string[];
}

interface ApplyContextProps {
  apply: Apply;
  setApply: React.Dispatch<SetStateAction<Apply>>;
}

const ApplyContext = createContext<ApplyContextProps | undefined>(undefined);

interface ApplyProviderProps {
  children: ReactNode;
}

export const ApplyProvider: React.FC<ApplyProviderProps> = ({ children }) => {
  const [apply, setApply] = useState<Apply>({
    name: '',
    company: '',
    email: '',
    phone: '',
    subPhone: '',
    site: '',
    checkedList: [],
  });

  return (
    <ApplyContext.Provider
      value={{
        apply,
        setApply,
      }}
    >
      {children}
    </ApplyContext.Provider>
  );
};

export const useApply = () => {
  const context = useContext(ApplyContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
