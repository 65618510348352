import styled from 'styled-components';
import { Link } from 'react-scroll';

const InquireBtn = styled.button`
  &:hover {
    cursor: pointer;
  }
  width: 150%;
  height: 60%;
  font-size: 1.1rem;
  font-family: Pretendard-SemiBold;
  background-color: white;
  opacity: 0.8;
  color: black;
  border: none;
  border-radius: 40px;
  margin-top: 10px;
  z-intdex: 20;
  @media (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

const InquireButton = () => {
  return (
    <div>
      <Link to='ApplyForm' spy={true} smooth={true} duration={1200}>
        <InquireBtn>문의하기</InquireBtn>
      </Link>
    </div>
  );
};

export default InquireButton;
