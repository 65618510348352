import styled from 'styled-components';
import invest01 from '../../img/invest/invest01.svg';
import invest02 from '../../img/invest/invest02.svg';
import invest03 from '../../img/invest/invest03.svg';
import InvestBg from '../../img/invest/invest04.svg';
import TitleForm from '../TitleFrom/TitleForm';

const Invest = () => {
  return (
    <Div>
      <Container>
        <TitleForm
          title='투자 지원'
          subTitle='Sigor의 기술력은 이미 검증되었습니다'
        />
        <ImgContainer>
          <InvestImg src={invest01} alt='투자지원1' />
          <InvestImg src={invest02} alt='투자지원2' />
          <InvestImg src={invest03} alt='투자지원3' />
        </ImgContainer>
      </Container>
    </Div>
  );
};

const Div = styled.div`
  padding: 7% 15%;
  background-image: url(${InvestBg});
  background-size: cover;
  @media (max-width: 1840px) {
    padding: 8% 7%;
  }
`;

const Container = styled.div`
  top: 0;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 450px) {
    margin-top: -8%;
  }
`;

const InvestImg = styled.img`
  width: 30%;
  height: 32%;
  border-radius: 10px;
`;
export default Invest;
