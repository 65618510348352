import styled from 'styled-components';
import apply01 from '../../img/Apply/apply01.svg';

const Introduce = () => {
  return (
    <Wrapper>
      <ContentDiv>
        <Title>Sigor와 함께 AI 도입을 시작해보세요</Title>
        <SubTitle>
          Sigor는 기존 AI 솔루션을 그대로 제공하지 않고 기업 맞춤으로 솔루션을
          디자인하여 제공합니다.
        </SubTitle>
        <ContentContainer>
          <Content>이미지 분석 솔루션 | </Content>
          <SubContent>
            이미지 기반 상품 인식, 분류 등의 솔루션을 제공합니다.
          </SubContent>
        </ContentContainer>
        <ContentContainer>
          <Content>
            WEB<Dot>•</Dot>APP 로그 구축 |{' '}
          </Content>
          <SubContent>
            고객 행동 분석을 위한 맞춤 로그 구축이 가능합니다.
          </SubContent>
        </ContentContainer>
        <ContentContainer>
          <Content>개인화 맞춤 마케팅 | </Content>
          <SubContent>
            고객의 행동 데이터룰 수집해 개인화 맞춤 마케팅 솔루션을 제공합니다.
          </SubContent>
        </ContentContainer>
        <ContentContainer>
          <Content>개인화 상품 추천 | </Content>
          <SubContent>
            고객의 로그를 AI가 학습하여 맞춤 상품 추천이 가능합니다.
          </SubContent>
        </ContentContainer>
        <ContentContainer>
          <Content>판매량 예측 및 재고 관리 | </Content>
          <SubContent>
            판매량 예측을 통한 온라인/오프라인 재고 관리가 가능합니다.
          </SubContent>
        </ContentContainer>
      </ContentDiv>
      <ImgDiv>
        <IntroImg src={apply01} />
      </ImgDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 450px) {
    width: 95%;
    margin: 3%;
  }
`;

const ContentDiv = styled.div``;

const ImgDiv = styled.div``;

const Title = styled.p`
  font-size: 2.5rem;
  color: white;
  font-family: Pretendard-Bold;
  margin-bottom: 15px;
  word-break: keep-all;
  @media (max-width: 450px) {
    font-size: 2rem;
    line-height: 120%;
  }
`;

const SubTitle = styled.p`
  font-size: 1.1rem;
  color: white;
  font-family: Pretendard-Light;
  word-break: keep-all;
  margin-bottom: 8%;
  word-break: keep-all;
  @media (max-width: 450px) {
    font-size: 0.8rem;
    line-height: 130%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.p`
  font-size: 1.1rem;
  font-family: Pretendard-Medium;
  color: #a0ceef;
  word-break: keep-all;
  margin-bottom: 16px;
  white-space: pre-wrap;
  @media (max-width: 450px) {
    font-size: 0.6rem;
  }
`;

const SubContent = styled.p`
  font-size: 0.9rem;
  font-family: Pretendard-Light;
  color: white;
  word-break: keep-all;
  @media (max-width: 450px) {
    font-size: 0.55rem;
    line-height: 110%;
  }
`;

const IntroImg = styled.img`
  margin: 0 0;
`;

const Dot = styled.span`
  justify-content: center;
`;

export default Introduce;
