import styled from 'styled-components';
import Header from '../Header/Header';
import Invest from '../Invest/Invest';
import TechIntro from '../TechIntro/TechIntro';
import TechUse from '../TechUse/TechUse';
import ApplyForm from '../ApplyFrom/ApplyForm';
import { Element } from 'react-scroll';
import ToMainBtn from './ToMainBtn';
import Select from '../Select/Select';
import Footer from '../Footer/Footer';

const MainLanding = () => {
  return (
    <Element name='top'>
      <ToMainBtn />
      <Header />
      <Div>
        <Content>
          <Title>AI 혁신의 길에</Title>
          <Title> Sigor가 함께하겠습니다</Title>
          <SubTitle>Sigor에서 기업 맞춤 전문 AI솔루션을 만나보세요</SubTitle>
        </Content>
        <VideoWrapper src='/video/mainvideo.mp4' loop autoPlay muted />
      </Div>
      <Invest />
      <TechIntro />
      <TechUse />
      <Select />
      <ApplyForm />
      <Footer />
    </Element>
  );
};

const VideoWrapper = styled.video`
  width: 100%;
  filter: brightness(70%);
  z-index: -100;
`;

const Div = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-bottom: 5%;
  @media (max-width: 450px) {
    margin: 0;
  }
`;

const Title = styled.p`
  font-family: Pretendard-Bold;
  font-size: 3.5rem;
  line-height: 130%;
  color: white;
  @media (max-width: 1420px) {
    font-size: 2rem;
    line-height: 120%;
  }
  @media (max-width: 450px) {
    font-size: 1rem;
  }
`;

const SubTitle = styled.p`
  font-family: Pretendard-Light;
  font-size: 2rem;
  color: white;
  margin-top: 3%;
  @media (max-width: 1420px) {
    top: 15%;
    font-size: 1.5rem;
  }
  @media (max-width: 450px) {
    font-size: 0.6rem;
    margin-top: 5%;
  }
`;

export default MainLanding;
